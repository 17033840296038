<template>
 <div>
     <template v-if=" this.$route.meta.guestOnly==true">
      <router-view />
    </template>
  <Dashboard v-else>
    <router-view/>

  </Dashboard>
 

 </div>

</template>
<script>
import Dashboard from './Layout/Dashboard.vue';
export default {
  name:'App',
  components:{
    Dashboard
  }
}
</script>

<style>
.form__box label {
    font-size: 14px;
    color: #ccc;
}

.form__box .form-control {
    background-color: #1d1930;
    border: 1px solid #2e2846;
    color: #fff;
    font-size: 15px;
    min-height: 40px;
    font-weight: 300;
}
.btn_save{
    background: #534686;
    border:1px solid #534686;
    color: #fff;

}
.form-bg{
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(8, 21, 66, .05);
    background-color: #1d1930;
    border: 1px solid #2e2846 ;
}
.VuePagination nav {
    flex-direction: row-reverse !important;
}


:root{

 --white:#ffffff;
 --black:#000000;
}

.swiper-button-next:after,  .swiper-button-prev:after {
    display:none
}

.page-link {
  z-index: 2 !important;
  color: #ffffffcc !important;
  background-color: #534686 !important;
  box-shadow: none !important;

  border-color: #534686 !important;
  font-size: 15px !important;
 
  font-weight:600;
}

.page-item.active .page-link {
  z-index: 2 !important;
  color: #534686 !important;
  box-shadow: none !important;
  background-color: #ffffffcc !important;
  border-color: #ffffffcc !important;
 
}

.pagination_box ul li {
  border: none !important;
  margin-bottom: 20px !important;
  margin-bottom: 0 !important;
  color: white;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  box-shadow: none !important;
}
.VuePagination__pagination {
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px !important;
 
  gap: 4px;
}
.VuePagination nav
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.VuePagination nav p {
  color:#ffffffcc;
 
  font-size: 15px;
  margin-bottom:0;
}

.VuePagination ul{
 

  margin-bottom:0;
}


/*swal css*/
.swal2-popup{

background-color: #1d1930;
}

.swal2-icon{

color:white !important;
border-color: white !important;
width:2em;
height:2em;
}
.swal2-title{
font-size:24px;
}

.swal2-html-container{
color:white !important;
}


.swal2-styled{
box-shadow:none !important;
}

.swal2-cancel{
background-color:#534686 !important;

}

.swal2-close:hover{

  color:#ccc !important;
}

div:where(.swal2-icon) .swal2-icon-content {
 
  font-size: 1.75em !important;
}





@font-face {
  font-family: "Roboto";
  src: url("@/assets/font/Roboto-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Roboto";
  src: url("@/assets/font/Roboto-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  src: url("@/assets/font/Roboto-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("@/assets/font/Roboto-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("@/assets/font/Roboto-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("@/assets/font/Roboto-Thin.ttf");
  font-weight: 100;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--white);
}

::-webkit-scrollbar-thumb {
  background: var(--ch-neon);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--ch-neon);
}


/*****************skeltor loader css */

.vue-skeletor {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
}

.vue-skeletor:not(.vue-skeletor--shimmerless):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(37, 22, 22, 0)
  );
  animation: shimmer 1.5s infinite;
  content: "";
}

.vue-skeletor--rect,
.vue-skeletor--circle {
  display: block;
}

.vue-skeletor--circle {
  border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
  border-radius: 9999px;
}

.vue-skeletor--text {
  line-height: 1;
  display: inline-block;
  width: 100%;
  height: inherit;
  vertical-align: middle;
  top: -1px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/*****************skeltor loader css *******/


@media only screen and (max-width:500px){
  .VuePagination nav{

    display:block;
  }

  .VuePagination nav p {
    margin-top:20px;
}
}

</style>
