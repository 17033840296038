import { createRouter, createWebHistory } from 'vue-router'
import Login from '../Layout/Login.vue'
import store from '../store/index.js'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { guestOnly: true },
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Dashboard/Dashboard.vue')
    },
    meta: { authOnly: true }
  },
  {
    path: '/userlist',
    name: 'User List',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Userlist/UserList.vue')
    },
    meta: { authOnly: true }
  },
  {
    path: '/promocode',
    name: 'Promocode Generate',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Promocode/Promocode.vue')
    },
    meta: { authOnly: true }
  },
  
  {
    path: '/planmanage',
    name: 'Plan Management',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Plan/Planmanage.vue')
    },
    meta: { authOnly: true }
  },
  {
    path: '/ticketlist',
    name: 'Ticket List',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/CustSupp/TicketList.vue')
    },
    meta: { authOnly: true }
  
  },
  {
    path: '/ticketcategory',
    name: 'Ticket Category',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/CustSupp/TicketCategory.vue')
    },
    meta: { authOnly: true }
  },
  {
    path: '/get-history',
    name: 'Payment History',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/PaymentHistory/History.vue')
    },
    meta: { authOnly: true }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

function isLoggedIn() {
  return store.getters.getIsLogin;
} 

router.beforeEach((to, from, next) => {
  if (to.meta.authOnly && !isLoggedIn()) { next({ name: 'Login' }) }
  else if (to.meta.guestOnly && isLoggedIn()) { next({ name: 'Dashboard' }) }
  else if (!to.name) { next({ name: 'Exchange' }) }
  else { next() }
})


export default router
