import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";


export default createStore({
  plugins: [createPersistedState()],

  
  state: {
    user: null,
    isLogin: false,
  },


  getters: {

    getIsLogin: state => {
      return state.isLogin;
    },
    getUser(state)  {
      return state.user;
    },


  },
  mutations: {
    setLogin(state, value) {
      state.isLogin = value;
    },
    SET_USER(state, user) {
      state.user = user;
    },


  },

})
