<template>
  <div>

    <footer class="footer">


    <div class="container-fluid">
        <div class="row justify-content-between align-items-center py-4">
            <div class="col-10">
                <p class="mb-0">Copyright 2023 © Koho theme by pixelstrap</p>
            </div>

            <div class="col-2">
                    <div class="text-end me-2">
                        <img src="../assets/footer/heart.svg"/>
                    </div>
            </div>
        </div>
    </div>
</footer>
  </div>
</template> 

<script>
export default {
    name:'Footer'
}
</script>

<style scoped>
p{

    font-family: Rubik,sans-serif;

    color: hsla(0,0%,100%,.8);
    font-size: 13px;
    
}
</style>>

