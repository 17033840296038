<template>
  <div >
   <section id="loginpage">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center  login  ">
         
                   <div class="col-xl-4 col-lg-5   col-sm-6">
                 <div class="loginform ">
                        <form class="gap-3 row" @submit.prevent="login">
                            <div class="text-center col-12">
                            <h2>Sign in to account</h2>
                            <p>Enter your email & password to login</p>
                            </div>
                           <div class="col-12">
                                <label for="form-email" class="form-label ">Email Address</label>
                                <input type="text" class="form-control shadow-none my-2" id="form-email" placeholder="Email"  v-model="email" />
                                <span class="error_msg"  style="color: rgb(242, 48, 81); font-size: 12px" v-if="v$.email.$error">
                                   {{ v$.email.$errors[0].$message }}
                               </span>
                           </div>

                           <div class="col-12">
                                <label for="basic-addon2" class="form-label ">Password</label>
                               
                                <div class="input-group  rounded-2 my-2">
                                    <input 
                                    v-model="password" 
                                    :type="hidden ? 'password' : 'text'"
                                    class="form-control shadow-none border-0 bg-transparent"
                                    placeholder="Password"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    />
                                    <span class="input-group-text bg-transparent border-0" style="color: rgb(242, 48, 81); font-size: 12px; cursor: pointer" id="basic-addon1" @click="hidden = !hidden">
                                    <img :src="hidden ? hide : show" alt="eye-icon" class="img-fluid"/></span>
                                  
                                 </div>
                                 <span class="error_msg" style="color: rgb(242, 48, 81); font-size: 12px"  v-if="v$.password.$error">
                                       {{ v$.password.$errors[0].$message }}
                                   </span>
                           </div>
                            <div class="col-12 text-center">
                                <button class="btn sign-btn w-50 py-2 " type="submit" v-if="!loader">Sign in</button>
                                <button class="btn sign-btn w-50 py-2" type="button"  v-if="loader"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                   Loading...
                               </button>
                            </div>
                        </form>
                 </div>
            </div>
        </div>
    </div>
   </section>
  </div>
</template>

<script>

/*dynamic import*/
import hide from "../../public/image/icon/hide.svg";
import show from "../../public/image/icon/show.svg";
import ApiClass from "../api/api.js";

import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";


export default {
name:'Login',
setup() {
    return {
      v$: useVuelidate(),
    };
  },
data(){
    return{

/*dynamic variables*/
      loader: false,
      hidden: true,
      hide: hide,
      show: show,
      email: "",
      password: "",
    }
},

/*form validation*/
validations() {
    return {
      email: {
        required: helpers.withMessage("Email is Required", required),
      },
      password: {
        required: helpers.withMessage("Password is Required", required),
        minLength: helpers.withMessage(
          "Password must be At least 6 character",
          minLength(6)
        ),
        maxLength: helpers.withMessage(
          "Password must be not be greater than 10 character",
          maxLength(10)
        ),
      },
    };
  },

  /*login api integration*/
  methods: {

    async login() {
      const result = await this.v$.$validate();
      if (!result) {
        // notify user form is invalid
        return;
      }
      this.loader = true;

      let body = {
        email : this.email,
        password: this.password
      }

      let res = await ApiClass.postRequest('user/login_by_admin' , false,  body);
      let data = res?.data;
        if(data.status_code== "0"){
          this.failed(data?.message)
           this.v$.$reset();
        }else{
          localStorage.setItem("token", data?.data?.token);
          this.success(data?.message);
          await new Promise(r=>setTimeout(r ,500))
           this.$store.commit("SET_USER", data?.data?.user);
           this.$store.commit("setLogin", true);
          this.$router.push({ path: "/dashboard" });
        }
      this.loader = false;
    },
  },

    showpassword(){

        this.show=!this.show
    }
}

</script>

<style scoped >
.input-group-text {
  /* background-color: var(--white);
  border: 2px solid var(--border); */
  color:var(--white) !important;
}
#loginpage{
background:#1d1930;
color:var(--white);
font-family:'Roboto';
white-space:nowrap

}

.login{

    min-height:100vh;
}

.loginform{

    /* box-shadow: 0 0 37px hsla(0,0%,100%,.05); */
    background-color:#151122;
   
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(8,21,66,.05);
    margin: 0 auto;
    color:var(--white);
}

.loginform h2{
    font-size:24px;
}

.loginform p{

  
    color: #b4b2ba;
}

.form-label{

    font-size: 15px;
    color: #b4b2ba;
}

.form-control,
.form-control:focus{

    border-color: #151122;
    background-color:#1d1930;
    color:var(--white);
    padding:10px;
    font-size: 14px;
    font-family:'Roboto'
   
}

.input-group{
    border-color: #151122;
    background-color:#1d1930;
    color:var(--white);
    font-family:'Roboto';
    font-size: 14px;
}

::placeholder{

    color: #b4b2ba;
}


.show-btn{

    color:var(--white);
    font-size:14px;
}


.form-check-input{
    background-color: #1d1930!important;

    width: 24px;
    height: 24px;
    cursor:pointer
}

.form-check-label{
    font-size:14px;
}

.forget-password{

    color: #b4b2ba;
    font-size:15px;
    letter-spacing: 1px;
    
}

.sign-btn{
    background-color: #534686 !important;
    color:var(--white);
    font-size:14px;
}

.loginform h3{

    font-size: 20px;
    font-weight: 500;
    color: #b4b2ba;
    position:relative;
    
    z-index: 1;
}


.loginform h3::before{
    content: "";
    position: absolute;
    width: 25%;
    height: 2px;
    background-color: rgba(83,70,134,.5);
    top: 10px;
    z-index: -1;
    left: 0;
}

.loginform h3::after{
    content: "";
    position: absolute;
    width: 25%;
    height: 2px;
    background-color: rgba(83,70,134,.5);
    top: 10px;
    z-index: -1;
    right: 0;
}

ul{
    list-style-type: none;
    padding-left:0px;
}
ul a{

    text-decoration:none;

}
ul li{
    width:40px;
    height:40px;

    display:flex;
    align-items:center;
    justify-content: center;
    border-radius:50%;
    border: 1px solid rgba(83,70,134,.102);
    background-color: rgba(83,70,134,.05);


}

ul li:hover{
    width:40px;
    height:40px;
    background-color: #534686 !important;
    display:flex;
    align-items:center;
    justify-content: center;
    border-radius:50%;

  

}

ul li img{

    width:20px;
}
ul li:hover img{
    filter: invert(100%);
}

.loginform .create p{

    font-size: 14px;
}

.loginform .create a{

    color: hsla(0,0%,100%,.8);
    
}

@media all and (min-width:320px) and (max-width:767px) {
    .loginform{



padding: 25px;

}
.loginform p{

  
font-size:15px;
}

}
</style>