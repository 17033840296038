import axios from "axios";
import store from '../store/index.js';

export default class ApiClass {

     /*Local Links*/
    
    static baseUrl = "https://node.chatgdcc.com/";
    

    /***************************************** Post request **************************************/
    static postRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        return axios.post(this.baseUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }


    /******************************************* get request **************************************/
    static getRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.get(this.baseUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error?.response?.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }


    /******************************** form data in json format ***************************************/
    static updateRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        return axios.put(this.baseUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    /**************************************** Delete request *****************************************/
    static deleteRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.delete(this.baseUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        })
    }

    /******************************* Configrations of header and parameters **************************/
    static config(isToken = true, headers = null, parameters = null) {
        var defaultHeaders = {
            Accept: "application/json"
        };
        var merge = {};
        if (isToken) {
            var token = { Authorization: "Bearer " + localStorage.getItem('token') }
            merge = Object.assign(defaultHeaders, token)
        }
        merge = Object.assign(defaultHeaders, headers)
        return {
            headers: merge,
            params: parameters
        }
    }

    //****************************** if the unautherntication Error..... ******************************/
    static unauthenticateRedirect() {
        localStorage.removeItem('user');
        store.commit('setLogin', false);
        localStorage.removeItem('token');
        store.commit('SET_USER', null);
        location.replace('/');
    }

}
